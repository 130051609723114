import type { LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import NotionPage from "~/components/notion/NotionPage.js";
import { pageLoader } from "~/services/page.server.js";

export default function Index() {
  const { pageMap, pageTree } = useLoaderData<typeof loader>();

  return <NotionPage pageMap={pageMap} pageTree={pageTree} fullWidth={true} />;
}

export async function loader({ request }: LoaderFunctionArgs) {
  return await pageLoader(request, "/");
}
